import {
    SUCCESS,
    REQUESTING,
    ERROR,
} from '../utils/constant'
import { get, post } from '../utils/apiRequest'
import { displayToast } from '../components/modules/toast.actions'

export const GET_GLOBAL_EVENTS_REQUEST = 'GET_GLOBAL_EVENTS_REQUEST'
export const GET_GLOBAL_EVENTS_SUCCESS = 'GET_GLOBAL_EVENTS_SUCCESS'
export const GET_GLOBAL_EVENTS_FAILURE = 'GET_GLOBAL_EVENTS_FAILURE'

export const GET_WOMEN_DAY_REQUEST = "GET_WOMEN_DAY_REQUEST"
export const GET_WOMEN_DAY_SUCCESS = "GET_WOMEN_DAY_SUCCESS"
export const GET_WOMEN_DAY_FAILURE = "GET_WOMEN_DAY_FAILURE"

export const GET_ALL_GLOBAL_EVENTS_REQUEST = 'GET_ALL_GLOBAL_EVENTS_REQUEST'
export const GET_ALL_GLOBAL_EVENTS_SUCCESS = 'GET_ALL_GLOBAL_EVENTS_SUCCESS'
export const GET_ALL_GLOBAL_EVENTS_FAILURE = 'GET_ALL_GLOBAL_EVENTS_FAILURE'

export const POST_USER_DATA_REQUEST = 'POST_USER_DATA_REQUEST'
export const POST_USER_DATA_SUCCESS = 'POST_USER_DATA_SUCCESS'
export const POST_USER_DATA_FAILURE = 'POST_USER_DATA_FAILURE'

export const POST_DEVICE_DETAILS_REQUEST = 'POST_DEVICE_DETAILS_REQUEST'
export const POST_DEVICE_DETAILS_SUCCESS = 'POST_DEVICE_DETAILS_SUCCESS'
export const POST_DEVICE_DETAILS_FAILURE = 'POST_DEVICE_DETAILS_FAILURE'

export const POST_CALENDAR_PAGE_REQUEST = "POST_CALENDAR_PAGE_REQUEST"
export const POST_CALENDAR_PAGE_SUCCESS = "POST_CALENDAR_PAGE_SUCCESS"
export const POST_CALENDAR_PAGE_FAILURE = "POST_CALENDAR_PAGE_FAILURE"

export function getGlobalEventsRequest() {
    return {
        type: GET_GLOBAL_EVENTS_REQUEST,
        status: REQUESTING
    }
}

export function getGlobalEventsSuccess(payload) {
    return {
        type: GET_GLOBAL_EVENTS_SUCCESS,
        status: SUCCESS,
        payload: payload
    }
}

export function getGlobalEventsFailure(error) {
    return {
        type: GET_GLOBAL_EVENTS_FAILURE,
        status: ERROR,
        error: error
    }
}

export function getWomenDayRequest() {
    return{
        type: GET_WOMEN_DAY_REQUEST,
        status: REQUESTING
    }
}

export function getWomenDaySuccess(payload) {
    return {
        type: GET_WOMEN_DAY_SUCCESS,
        status: SUCCESS,
        payload
    }
}

export function getWomenDayFailure(error) {
    return {
        type: GET_WOMEN_DAY_FAILURE,
        status: ERROR,
        error
    }
}

export function getAllGlobalEventsRequest() {
    return {
        type: GET_ALL_GLOBAL_EVENTS_REQUEST,
        status: REQUESTING
    }
}

export function getAllGlobalEventsSuccess(payload) {
    return {
        type: GET_ALL_GLOBAL_EVENTS_SUCCESS,
        status: SUCCESS,
        payload
    }
}

export function getAllGlobalEventsFailure(error) {
    return {
        type: GET_ALL_GLOBAL_EVENTS_FAILURE,
        status: ERROR,
        error
    }
}

export function postUserDataRequest() {
    return {
        type: POST_USER_DATA_REQUEST,
        status: REQUESTING
    }
}

export function postUserDataSuccess(statusCode) {
    return {
        type: POST_USER_DATA_SUCCESS,
        status: SUCCESS,
        statusCode
    }
}

export function postUserDataFailure(error) {
    return {
        type: POST_USER_DATA_FAILURE,
        status: ERROR,
        error
    }
}

export function postDeviceDetailsRequest() {
    return {
        type: POST_DEVICE_DETAILS_REQUEST,
        status: REQUESTING
    }
}

export function postDeviceDetailsSuccess(deviceId) {
    return {
        type: POST_DEVICE_DETAILS_SUCCESS,
        status: SUCCESS,
        deviceId
    }
}

export function postDeviceDetailsFailure(error) {
    return {
        type:POST_DEVICE_DETAILS_FAILURE,
        status: ERROR,
        error
    }
}

export function postCalendarPageRequest() {
    return {
        type: POST_CALENDAR_PAGE_REQUEST,
        status: REQUESTING
    }
}

export function postCalendarPageSuccess(payload) {
    return {
        type: POST_CALENDAR_PAGE_SUCCESS,
        status: SUCCESS,
        payload
    }
}

export function postCalendarPageFailure(error) {
    return {
        type: POST_CALENDAR_PAGE_FAILURE,
        status: ERROR,
        error
    }
}

export function getEvents() {
    return async (dispatch) => {
        dispatch(getGlobalEventsRequest())
        try{
            let url = `globalEvents/get-event-list?globalEventTypeIds=1`
            const result = await get(url)
            const resultJson = await result.data
            if (resultJson.error) {
                throw new Error(resultJson.message)
            }

            return dispatch(getGlobalEventsSuccess(resultJson))
        } catch (e) {
            dispatch(displayToast(2, e.message))
            return dispatch(getGlobalEventsFailure(e.message))
        }
    }
}

export function getAllEvents(eventId, globalEventIds, search, newId, page, limit) {
    return async (dispatch) => {
        dispatch(getAllGlobalEventsRequest())
        try{
            const eventIdsArray = Array.isArray(eventId) ? eventId : [eventId];

            const eventIdFilter = `[${eventIdsArray.join(',')}]`;

            const searchRes = search === "" ? "" : search
            
            let url = `globalEvents/get-global-events?eventListIds=${eventIdFilter === '[]' ? "" : eventIdFilter}&eventIds=${globalEventIds}&searchResult=${searchRes}&globalEventTypeIds=[1,2]&page=${page}&limit=${limit}`
            const result = await get(url)
            const resultJson = await result.data
            if (resultJson.error) {
                throw new Error(resultJson.message)
            }

            return dispatch(getAllGlobalEventsSuccess(resultJson))
        } catch (e) {
            dispatch(displayToast(2, e.message))
            return dispatch(getAllGlobalEventsFailure(e.message))
        }
    }
}  

export function getWomenDay(payload) {
    return async (dispatch) => {
        dispatch(getWomenDayRequest());

        try {
            let url = `globalEvents/get-event-list?globalEventTypeIds=2`
            const result = await get(url)
            const resultJson = await result.data;
            if (resultJson.error){
                throw new Error(resultJson.message);
            }
            dispatch(getWomenDaySuccess(resultJson));
        } catch (e) {
            dispatch(displayToast(2, e.message))
            return dispatch(getWomenDayFailure(e.message))
        }
    }
}

export function postData(payload) {
    return async (dispatch) => {
        dispatch(postUserDataRequest());

        try {
            let url = `globalEvents/global-attendees-reg`
            const result = await post(url, payload)
            const resultJson = await result.data;
            if (resultJson.error) {
                throw new Error(resultJson.message);
            }
            dispatch(postUserDataSuccess(resultJson.statusCode));
            return { status: resultJson.status, statusCode: resultJson.statusCode };
        } catch (e) {
            dispatch(displayToast(2, e.message))
            dispatch(postUserDataFailure(e.message))
            throw e; 
        }
    }
}

export function postDeviceDetail(id) {
    return async (dispatch) => {
        dispatch(postDeviceDetailsRequest())
        try{
            let url = `globalEvents/get-hover?topicId=${id}`
            const result = await get(url)
            const resultJson = await result.data
            if (resultJson.error) {
                throw new Error(resultJson.message)
            }
            dispatch(postDeviceDetailsSuccess(resultJson.deviceId))
        } catch (e) {
            dispatch(postDeviceDetailsFailure(e.message))
            throw e
        }
    }
}

export function postCalendarPage(activity) {
    return async (dispatch) => {
        dispatch(postCalendarPageRequest())
        try{
            let url = `globalEvents/global-event-activities`
            const result = await get(url)
            const resultJson = await result.data
            if (resultJson.error) {
                throw new Error(resultJson.message)
            }
            dispatch(postCalendarPageSuccess(resultJson))
        } catch (e) {
            dispatch(postCalendarPageFailure(e.message))
        }
    }
}